<template>
  <div class="team-members" :class="modal ? 'in-modal' : ''">
    <b-row>
      <b-col
        :md="modal ? 4 : 6"
        v-for="(team, index) in data"
        :key="index"
        class="team-members__col"
      >
        <TeamMembersBadge :memberDetails="team" />
      </b-col>
    </b-row>
  </div>
</template>
<script>
import TeamMembersBadge from "@/components/Teams/TeamMembersBadge.vue";
export default {
  props: ["data", "modal"],
  components: {
    TeamMembersBadge
  },
  watch: {
    data(val) {
      if (val.length > 6) {
        this.$parent.$emit("hasData");
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.team-members {
  margin: 15px 20px;

  .team-members__col {
    margin-bottom: 20px;
  }

  &.in-modal {
    min-height: calc(221px - 32px);
    .team-members__col {
      padding-bottom: 20px;
      border-bottom: 1px solid #acacac;
    }
  }
}
</style>
